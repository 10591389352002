var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var BK,DK,FK,IK,LK;$CLJS.wK=function(a){return $CLJS.Hh($CLJS.q($CLJS.fB),a,$CLJS.kF)};$CLJS.xK=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.yK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.zK=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.AK=new $CLJS.M(null,"object-id","object-id",-754527291);BK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.CK=new $CLJS.M(null,"operators","operators",-2064102509);DK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.EK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);FK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.GK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.HK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
IK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.JK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.KK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);LK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.MK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.NK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.sD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ZF],null)],null)],null));$CLJS.Y(LK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,DK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.wD],null)],null)],null));$CLJS.Y(FK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.gF],null),LK,$CLJS.nD],null));
$CLJS.Y(IK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.gF],null),DK,$CLJS.eK],null));
$CLJS.FF.g($CLJS.gF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.gF],null),DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.eK,$CLJS.nD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.yi,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.WA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,IK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,FK],null)],null)],null));$CLJS.mE($CLJS.gF,$CLJS.kF);$CLJS.HD.m(null,$CLJS.gF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.pi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.VD});$CLJS.tE($CLJS.qA,$CLJS.H([$CLJS.nD]));$CLJS.HD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.pi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.VD});
$CLJS.mE($CLJS.qA,$CLJS.kF);$CLJS.Y(BK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.sD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.nD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.nD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.nD],null)],null)],null));
$CLJS.FF.g($CLJS.WE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.WE],null),BK,$CLJS.yj],null));$CLJS.HD.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.pi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.VD});$CLJS.mE($CLJS.WE,$CLJS.kF);$CLJS.tE($CLJS.EE,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null)],null)]));$CLJS.mE($CLJS.EE,$CLJS.kF);
$CLJS.tE($CLJS.bF,$CLJS.H([$CLJS.Zr,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JJ],null)],null)]));$CLJS.mE($CLJS.bF,$CLJS.kF);
$CLJS.Y($CLJS.kF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.JD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Dd,$CLJS.Gk.g($CLJS.q($CLJS.fB),$CLJS.kF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.wK(a)}],null)],null));