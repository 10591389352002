var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Q3,S3,U3,V3,W3,X3,Y3,Era;Q3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=Q3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Q3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.R3=function(a,b){return Q3($CLJS.c_(a),b)};
S3=function(a,b,c){var d=$CLJS.EV(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.WE);d=$CLJS.pG($CLJS.kk.j($CLJS.Tg([c]),$CLJS.uD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.dD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.sr,c,$CLJS.CE,a,$CLJS.EK,b],null));return d};$CLJS.T3=function(a){return $CLJS.H1($CLJS.sF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};U3=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
V3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);W3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);X3=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);Y3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Era=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.P_.m(null,$CLJS.WE,function(a,b){var c=$CLJS.Be($CLJS.WE.h($CLJS.EV(a,b)));return $CLJS.n(c)?$CLJS.K1($CLJS.FD("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.l_.v(a,b,v,$CLJS.m_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.l_.v(a,
b,t,$CLJS.m_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.T_.m(null,$CLJS.WE,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.tA);e=$CLJS.J.g(e,$CLJS.pi);c=$CLJS.I(c,2,null);c=S3(a,b,c);return $CLJS.Ck.l($CLJS.H([$CLJS.j_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.KJ,$CLJS.hK,$CLJS.D0,$CLJS.uD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.pi,e],null):null]))});
$CLJS.M_.m(null,$CLJS.WE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.l_.v(a,b,S3(a,b,c),d)});$CLJS.mE(W3,Y3);
for(var Z3=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.hF,$CLJS.pF],null)),$3=null,a4=0,b4=0;;)if(b4<a4){var Fra=$3.X(null,b4);$CLJS.mE(Fra,W3);b4+=1}else{var c4=$CLJS.y(Z3);if(c4){var d4=c4;if($CLJS.vd(d4)){var e4=$CLJS.ic(d4),Gra=$CLJS.jc(d4),Hra=e4,Ira=$CLJS.D(e4);Z3=Gra;$3=Hra;a4=Ira}else{var Jra=$CLJS.z(d4);$CLJS.mE(Jra,W3);Z3=$CLJS.B(d4);$3=null;a4=0}b4=0}else break}
$CLJS.M_.m(null,W3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.l_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.dD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.dD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.FD("Count");case "cum-count":return $CLJS.FD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.N_.m(null,W3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.T_.m(null,X3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.iH($CLJS.T_,Y3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.vj,$CLJS.RB)});
$CLJS.mE(X3,Y3);$CLJS.mE(W3,X3);$CLJS.mE($CLJS.LE,X3);$CLJS.M_.m(null,$CLJS.PE,function(){return $CLJS.FD("Case")});$CLJS.N_.m(null,$CLJS.PE,function(){return"case"});$CLJS.mE(V3,Y3);
for(var f4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.aF,$CLJS.BF,$CLJS.LE,$CLJS.Ej,$CLJS.tF,$CLJS.ti,$CLJS.uF,$CLJS.sF,$CLJS.ME],null)),g4=null,h4=0,i4=0;;)if(i4<h4){var Kra=g4.X(null,i4);$CLJS.mE(Kra,V3);i4+=1}else{var j4=$CLJS.y(f4);if(j4){var k4=j4;if($CLJS.vd(k4)){var l4=$CLJS.ic(k4),Lra=$CLJS.jc(k4),Mra=l4,Nra=$CLJS.D(l4);f4=Lra;g4=Mra;h4=Nra}else{var Ora=$CLJS.z(k4);$CLJS.mE(Ora,V3);f4=$CLJS.B(k4);g4=null;h4=0}i4=0}else break}
$CLJS.N_.m(null,V3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.M_.m(null,V3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.l_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.dD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.dD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.dD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.dD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.dD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.dD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.dD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.dD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.dD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.M_.m(null,$CLJS.SE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.dD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.l_.v(a,b,e,d)]))});$CLJS.N_.m(null,$CLJS.SE,function(){return"percentile"});
$CLJS.mE(U3,Y3);for(var m4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SE,$CLJS.ME],null)),n4=null,o4=0,p4=0;;)if(p4<o4){var Pra=n4.X(null,p4);$CLJS.mE(Pra,U3);p4+=1}else{var q4=$CLJS.y(m4);if(q4){var r4=q4;if($CLJS.vd(r4)){var s4=$CLJS.ic(r4),Qra=$CLJS.jc(r4),Rra=s4,Sra=$CLJS.D(s4);m4=Qra;n4=Rra;o4=Sra}else{var Tra=$CLJS.z(r4);$CLJS.mE(Tra,U3);m4=$CLJS.B(r4);n4=null;o4=0}p4=0}else break}
$CLJS.T_.m(null,U3,function(a,b,c){var d=$CLJS.dk.g,e=$CLJS.iH($CLJS.T_,Y3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.dk,a,$CLJS.vj)});$CLJS.M_.m(null,$CLJS.zF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.dD("Sum of {0} matching condition",$CLJS.H([$CLJS.l_.v(a,b,e,d)]))});
$CLJS.N_.m(null,$CLJS.zF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.O_.j(a,b,d))].join("")});$CLJS.mE($CLJS.zF,Y3);$CLJS.M_.m(null,$CLJS.nF,function(){return $CLJS.FD("Share of rows matching condition")});$CLJS.N_.m(null,$CLJS.nF,function(){return"share"});$CLJS.T_.m(null,$CLJS.nF,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.iH($CLJS.T_,Y3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.vj,$CLJS.iC)});
$CLJS.mE($CLJS.nF,Y3);$CLJS.M_.m(null,$CLJS.pF,function(){return $CLJS.FD("Count of rows matching condition")});$CLJS.T_.m(null,Y3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.Ck.l;d=$CLJS.n(d)?$CLJS.Bl($CLJS.j_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,$CLJS.vj],null)):null;var f=$CLJS.iH($CLJS.T_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Ck,$CLJS.H([d,a]))});
$CLJS.Ura=function(){function a(d){return $CLJS.H1($CLJS.Rw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.H1($CLJS.Rw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.nV.m(null,$CLJS.WE,function(a){return a});
$CLJS.t4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.WA(f),$CLJS.OJ))f=$CLJS.pV(f);else return $CLJS.M1(d,e,$CLJS.WE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.u4=function(){function a(d,e){return $CLJS.Be($CLJS.WE.h($CLJS.EV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.v4=function(){function a(d,e){var f=$CLJS.Be($CLJS.WE.h($CLJS.EV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.j_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.tA.h(l);return m.call($CLJS.R,$CLJS.G1(l,$CLJS.pi,$CLJS.n(t)?t:$CLJS.ej),$CLJS.KJ,$CLJS.hK,$CLJS.H([$CLJS.D0,$CLJS.uD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.M_.m(null,$CLJS.NF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.JF);return $CLJS.iD.h(a.o?a.o():a.call(null))});$CLJS.U_.m(null,$CLJS.NF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.$E);b=$CLJS.J.g(d,$CLJS.JF);c=$CLJS.J.g(d,$CLJS.IF);d=$CLJS.J.g(d,$CLJS.MY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.K_,$CLJS.$z(a),$CLJS.H([$CLJS.I_,c]));return null!=d?$CLJS.R.j(a,$CLJS.t_,d):a});
$CLJS.Vra=function(){function a(d,e){var f=function(){var m=$CLJS.dz.h($CLJS.Z0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.EV(d,e),l=$CLJS.Y_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.kk.j($CLJS.ef(function(m){m=$CLJS.LF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.tl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.IF),u=$CLJS.J.g(m,$CLJS.GF);if($CLJS.Ra(t))return m;if($CLJS.E.g(u,$CLJS.ur))return $CLJS.R.j(m,$CLJS.BV,l);t=$CLJS.Be($CLJS.y0(function(v){return $CLJS.CZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.BV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.Vs,$CLJS.NF)})),$CLJS.OF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Wra=function(){function a(d,e){return $CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E.h(d),$CLJS.N,$CLJS.qV.h(e)],null))}function b(d){if($CLJS.Ra($CLJS.IF.h(d)))return $CLJS.oV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E.h(d),$CLJS.N],null));var e=$CLJS.$E.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Era,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();