var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.cache.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.field.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.time.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var Wya=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},Xya=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},Yya=function(a,b,c){if(b in a)return a[b];c=c();return a[b]=c},Zya=function(a,b,c,d){return $CLJS.h_(a,b,c,d,$CLJS.Ck.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.dZ,U9],null),new $CLJS.h(null,1,[$CLJS.cZ,!0],null)])))},$ya=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tA);b=$CLJS.J.g(b,$CLJS.pi);a=$CLJS.n(b)?
b:a;return $CLJS.Dz(a,$CLJS.Aj)||$CLJS.Dz(a,$CLJS.Xi)},aza=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(f,k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),d);return function l(k){return new $CLJS.ie(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);A=$CLJS.V1.v(a,b,A,d);A=$CLJS.J.j(e,A,-1);v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),
null)}v=$CLJS.z(m);v=$CLJS.V1.v(a,b,v,d);return $CLJS.ae($CLJS.J.j(e,v,-1),l($CLJS.Hc(m)))}return null}},null,null)}(c)},V9=function(a,b){return new $CLJS.uh(function(){try{return $CLJS.Q9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.Mz($CLJS.Cx);$CLJS.n($CLJS.Lz("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.Kz("metabase.lib.js.metadata",d,$CLJS.tV("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.sU(c)])),c):$CLJS.Kz("metabase.lib.js.metadata",d,$CLJS.tV(c,$CLJS.H(["Error parsing %s objects: %s",
a,$CLJS.sU(c)])),null));return null}throw e;}})},bza=function(a,b){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.hd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.XJ.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),
e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.XJ.h(v),b):x}()))return $CLJS.ae(v,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.OD.h(a);return null==d?null:$CLJS.q(d)}())},cza=function(a,b){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=
$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.hd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.XJ.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=
v;return $CLJS.n(x)?$CLJS.E.g($CLJS.XJ.h(v),b):x}()))return $CLJS.ae(v,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.K9.h(a);return null==d?null:$CLJS.q(d)}())},dza=function(a,b){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.hd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;
return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.XJ.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.XJ.h(v),b):x}()))return $CLJS.ae(v,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(function(){var d=null==
a?null:$CLJS.L9.h(a);return null==d?null:$CLJS.q(d)}())},W9=function(a,b,c,d){this.fg=a;this.Fg=b;this.metadata=c;this.mi=d;this.C=393216;this.I=0},eza=function(a,b){var c=new $CLJS.h(null,6,[$CLJS.wya,V9($CLJS.UO,b),$CLJS.N9,V9($CLJS.Nj,b),$CLJS.OD,V9($CLJS.gF,b),$CLJS.oya,V9($CLJS.vM,b),$CLJS.K9,V9($CLJS.bF,b),$CLJS.L9,V9($CLJS.EE,b)],null),d=$CLJS.Mz($CLJS.Bx);$CLJS.n($CLJS.Lz("metabase.lib.js.metadata",d))&&$CLJS.Kz("metabase.lib.js.metadata",d,$CLJS.xv.l($CLJS.H(["Created metadata provider for metadata"])),
null);return new W9(a,b,c,$CLJS.N)},fza=function(a){return $CLJS.Va(a)?Wya(a,function(b){return void 0!==b}):a},gza=function(a){return $CLJS.gs($CLJS.My(Xya(a,function(b){return fza(b)})),function(b){return $CLJS.nk.j($CLJS.Ez(b,$CLJS.mh),$CLJS.Ui,$CLJS.mh)})},X9=function(a,b){if($CLJS.tY(b))a=b;else{var c=$CLJS.p.h(a);a=$CLJS.h_(c,b,b,$CLJS.Qe(eza,a),new $CLJS.h(null,2,[$CLJS.dZ,$CLJS.g_,$CLJS.cZ,!0],null))}return a},hza=function(a){a=$CLJS.$z(a);const b=a.length-1;a=0<=b&&a.indexOf("?",b)==b?["is-",
$CLJS.Gz(a,/\?$/,"")].join(""):a;return $CLJS.FA.h?$CLJS.FA.h(a):$CLJS.FA.call(null,a)},$9=function(a){return $CLJS.sd(a)?Y9.h?Y9.h(a):Y9.call(null,a):$CLJS.rd(a)?Z9.h?Z9.h(a):Z9.call(null,a):a instanceof $CLJS.M?$CLJS.$z(a):a},iza=function(a,b){return $CLJS.SV($CLJS.SV((0,$CLJS.BX)($CLJS.T9($CLJS.My(a))),$CLJS.iP,function(c){return $CLJS.Ck.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.FP,$CLJS.N],null),c]))}),$CLJS.CE,function(c){var d=function(){var e=$CLJS.OD.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.ie(null,
function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);v.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,A,null],null));x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}v=$CLJS.z(m);return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,v,null],null),l($CLJS.Hc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.OD,$CLJS.i8(d))})},jza=function(a,b){function c(l,
m){l=$CLJS.Ra($CLJS.tA.h(m))?$CLJS.dk.g(l,$CLJS.tA):l;m=$CLJS.Ra($CLJS.pi.h(m))?$CLJS.dk.g(l,$CLJS.pi):l;return $CLJS.Be(m)}var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return $CLJS.E.g(new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c(a,b)],null),new $CLJS.P(null,3,5,$CLJS.Q,[f,k,c(b,a)],null))},kza=function(a,b){var c=$CLJS.EV(a,b),d=$CLJS.HV();return $CLJS.re($CLJS.cf.g(function(e){return $CLJS.nk.j($CLJS.R.j(e,$CLJS.MY,!0),$CLJS.U,
d)},$CLJS.W_.j(a,b,c)))},lza=function(a){return $CLJS.n(function(){var b=$CLJS.z(a),c=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.EE,null,$CLJS.WE,null,$CLJS.bF,null],null),null);return c.h?c.h(b):c.call(null,b)}())?$CLJS.rk.j(a,0,2):$CLJS.nk.v(a,2,$CLJS.gs,function(b){return $CLJS.he(b)?$CLJS.$z(b):b})},a$=function(a){return $CLJS.WX.h($CLJS.nk.j($CLJS.Ly(a,$CLJS.H([$CLJS.ji,!0])),0,$CLJS.mh))},mza=function(a){var b=$CLJS.Va(a)?a:null;return $CLJS.n(b)?(a=$CLJS.S9.h?$CLJS.S9.h(b):$CLJS.S9.call(null,
b),$CLJS.E.g($CLJS.KJ.h(a),$CLJS.hK)?(b=a$(b.field_ref),$CLJS.R.j(a,$CLJS.D0,$CLJS.ed(b))):a):a},b$=function(a,b){var c=null==a?null:$CLJS.z(a);c=null==c?null:$CLJS.E.g(c,$CLJS.WE);return $CLJS.n(c)?$CLJS.R.j(b,$CLJS.D0,$CLJS.ed(a)):b},c$=function(a,b,c){b=$CLJS.x9.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(k,l){$CLJS.I(l,
0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.VX,e=$CLJS.UX;$CLJS.VX=a;$CLJS.UX=b;try{var f=function(){var k=c.field_ref;return $CLJS.n(k)?a$(k):null}();return b$(f,$CLJS.S9.h?$CLJS.S9.h(c):$CLJS.S9.call(null,c))}finally{$CLJS.UX=e,$CLJS.VX=d}},nza=function(a){return function(b){var c=function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.S9.h?$CLJS.S9.h(e):$CLJS.S9.call(null,e)}(),d=function(){var e=$CLJS.r1.h(c);
return $CLJS.n(e)?a$(e):null}();return new $CLJS.h(null,3,[$CLJS.Ci,b$(d,c),$CLJS.zK,d,$CLJS.hj,b.value],null)}},U9=function U9(a){switch(arguments.length){case 0:return U9.o();case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};U9.o=function(){return{}};U9.j=function(a,b,c){a=$CLJS.Aa(a,b);return $CLJS.n(a)?a.get(c):null};
U9.v=function(a,b,c,d){return Yya(a,b,function(){return new WeakMap}).set(c,d)};U9.A=4;$CLJS.g=W9.prototype;$CLJS.g.P=function(a,b){return new W9(this.fg,this.Fg,this.metadata,b)};$CLJS.g.O=function(){return this.mi};$CLJS.g.ni=$CLJS.yc;$CLJS.g.vg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.K9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){return cza(this.metadata,b)};
$CLJS.g.Ag=function(a,b){a=this.metadata;a=null==a?null:$CLJS.N9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ug=function(a,b){return bza(this.metadata,b)};$CLJS.g.tg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.OD.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.yg=function(a,b){return dza(this.metadata,b)};$CLJS.g.zg=function(a,b){return $CLJS.D9($CLJS.D9(this.Fg,"settings"),$CLJS.Xg(b))};
$CLJS.g.rg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.oya.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.xg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.L9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.sg=function(){var a=this.metadata,b=this.fg;a=null==a?null:$CLJS.wya.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};
var oza=new $CLJS.M(null,"can-save","can-save",-462869392),pza=new $CLJS.M(null,"can-run","can-run",1635004421);var d$=function d$(a){switch(arguments.length){case 1:return d$.h(arguments[0]);case 2:return d$.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.extract_template_tags",d$);d$.h=function(a){return d$.g(a,$CLJS.N)};d$.g=function(a,b){return $CLJS.Fk($CLJS.Jxa.l($CLJS.H([a,gza(b)])))};d$.A=2;
var e$=function e$(a){switch(arguments.length){case 2:return e$.g(arguments[0],arguments[1]);case 3:return e$.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.query",e$);e$.g=function(a,b){return $CLJS.A9.l($CLJS.H([a,b]))};e$.j=function(a,b,c){return Zya($CLJS.p.h(a),b,c,function(d){return $CLJS.A9.l($CLJS.H([b,$CLJS.WX.h((0,$CLJS.BX)($CLJS.G1($CLJS.E7(d),$CLJS.Ui,$CLJS.CE)))]))})};e$.A=3;
var f$=function f$(a){return $CLJS.he(a)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.sd(a)?$CLJS.gs(a,f$):$CLJS.rd(a)?$CLJS.cf.g(f$,a):a},Y9=$CLJS.Zz(function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=hza(d);c=$9.h?$9.h(c):$9.call(null,c);b[d]=c;return b},{},a)}),Z9=$CLJS.Zz(function(a){return $CLJS.re($CLJS.cf.g($9,a))}),g$=function g$(a){switch(arguments.length){case 1:return g$.h(arguments[0]);case 2:return g$.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",
$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.order_by_clause",g$);g$.h=function(a){return g$.g(a,$CLJS.dF)};g$.g=function(a,b){return $CLJS.Mxa.l($CLJS.H([$CLJS.z9.l($CLJS.H([$CLJS.Ly(a,$CLJS.H([$CLJS.ji,!0]))])),$CLJS.mh.h(b)]))};g$.A=2;var h$=function h$(a){switch(arguments.length){case 2:return h$.g(arguments[0],arguments[1]);case 3:return h$.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.xa("metabase.lib.js.available_binning_strategies",h$);h$.g=function(a,b){return $CLJS.re($CLJS.y9.l($CLJS.H([a,b])))};h$.j=function(a,b,c){return $CLJS.re($CLJS.y9.l($CLJS.H([a,b,c])))};h$.A=3;var i$=function i$(a){switch(arguments.length){case 2:return i$.g(arguments[0],arguments[1]);case 3:return i$.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.available_temporal_buckets",i$);
i$.g=function(a,b){return $CLJS.re($CLJS.C9.l($CLJS.H([a,b])))};i$.j=function(a,b,c){return $CLJS.re($CLJS.C9.l($CLJS.H([a,b,c])))};i$.A=3;
var j$=function j$(a,b){return $CLJS.ud(a)&&$CLJS.ud(b)&&$CLJS.E.l($CLJS.z(a),$CLJS.z(b),$CLJS.H([$CLJS.gF]))?jza(a,b):$CLJS.sd(a)&&$CLJS.sd(b)?$CLJS.E.g($CLJS.Wg($CLJS.Ng(a)),$CLJS.Wg($CLJS.Ng(b)))&&$CLJS.Le(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.J.g(b,e);return j$.g?j$.g(d,e):j$.call(null,d,e)},a):$CLJS.rd(a)&&$CLJS.rd(b)?$CLJS.E.g($CLJS.D(a),$CLJS.D(b))&&$CLJS.Le($CLJS.hl,$CLJS.cf.j(j$,a,b)):$CLJS.E.g(a,b)},k$=function k$(a){switch(arguments.length){case 2:return k$.g(arguments[0],
arguments[1]);case 3:return k$.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.query_EQ_",k$);k$.g=function(a,b){return k$.j(a,b,null)};k$.j=function(a,b,c){a=iza(a,c);b=iza(b,c);return j$(a,b)};k$.A=3;var l$=function l$(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return l$.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};
$CLJS.xa("metabase.lib.js.filter_clause",l$);l$.l=function(a,b,c){return $CLJS.Ne.v($CLJS.Ywa,a,b,c)};l$.A=2;l$.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};var m$=function m$(a){switch(arguments.length){case 3:return m$.j(arguments[0],arguments[1],arguments[2]);case 4:return m$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.xa("metabase.lib.js.with_different_database",m$);m$.j=function(a,b,c){return m$.v(a,b,c,null)};m$.v=function(a,b,c,d){return $CLJS.Hxa.l($CLJS.H([a,X9(b,c),$CLJS.Ly(d,$CLJS.H([$CLJS.ji,!0]))]))};m$.A=4;var qza=nza(function(a){return a.col}),rza=nza(function(a){return a.column}),n$=function n$(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return n$.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};
$CLJS.xa("metabase.lib.js.drill_thru",n$);n$.l=function(a,b,c,d){return $CLJS.Ne.M($CLJS.Bwa,a,b,c,d)};n$.A=3;n$.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){return $CLJS.i_($CLJS.mh.g("returned-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return kza(a,b)})},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.x9.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);
l=$CLJS.J.g(l,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.VX,e=$CLJS.UX;$CLJS.VX=a;$CLJS.UX=b;try{var f=$CLJS.LX.h(c);return $CLJS.Fk(function(){var k;if(k=$CLJS.ud(f)){k=$CLJS.z(f);var l=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.hj,null,$CLJS.uL,null],null),null);k=l.h?l.h(k):l.call(null,k)}return $CLJS.n(k)?$CLJS.J.g(f,1):f}())}finally{$CLJS.UX=e,$CLJS.VX=d}},drop_stage_if_empty:function(a){return $CLJS.Zxa.l($CLJS.H([a]))},extract_template_tags:d$,engine:function(a){return $CLJS.Xg($CLJS.Cxa.l($CLJS.H([a])))},
filter_operator:function(a,b,c){return $CLJS.Zwa.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.qwa.l($CLJS.H([a]))},query:e$,columns_group_columns:function(a){return $CLJS.re($CLJS.wwa.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.mxa.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.$z(a):$CLJS.re(a)},aggregate:function(a,b,c){return $CLJS.lwa.l($CLJS.H([a,b,$CLJS.Ly(c,$CLJS.H([$CLJS.ji,!0]))]))},join_conditions:function(a){return $CLJS.re($CLJS.lxa.l($CLJS.H([a])))},query_EQ_:k$,temporal_bucket:function(a){return $CLJS.dya.l($CLJS.H([a]))},
available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.x9.l($CLJS.H([a,b])),l=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),k);k=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),k);var m=$CLJS.VX,t=$CLJS.UX;$CLJS.VX=l;$CLJS.UX=k;try{var u=function(){var v=$CLJS.n(c)?
c.field_ref:c;return $CLJS.n(v)?a$(v):null}();return $CLJS.re($CLJS.Awa.l($CLJS.H([a,b,$CLJS.Ck.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Ci,$CLJS.n(c)?b$(u,$CLJS.S9.h?$CLJS.S9.h(c):$CLJS.S9.call(null,c)):null,$CLJS.zK,u,$CLJS.hj,void 0===d?null:null==d?$CLJS.MK:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Yh,$CLJS.mk.g(qza,e)],null):null,$CLJS.n($CLJS.Be(f))?new $CLJS.h(null,1,[$CLJS.HK,$CLJS.mk.g(rza,f)],null):null]))])))}finally{$CLJS.UX=t,$CLJS.VX=m}},has_clauses:function(a,b){return $CLJS.$xa.l($CLJS.H([a,
b]))},remove_clause:function(a,b,c){return $CLJS.Sxa.l($CLJS.H([a,b,$CLJS.z9.l($CLJS.H([$CLJS.Ly(c,$CLJS.H([$CLJS.ji,!0]))]))]))},source_table_or_card_id:function(a){var b=$CLJS.ZZ(a);if($CLJS.n(b))return b;a=$CLJS.L1(a);return null==a?null:["card__",$CLJS.p.h(a)].join("")},legacy_ref:function(a,b,c){b=$CLJS.WE.h($CLJS.EV(a,b));a=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);
b=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.VX,e=$CLJS.UX;$CLJS.VX=a;$CLJS.UX=b;try{return $CLJS.Fk(lza($CLJS.LX.h($CLJS.B9.l($CLJS.H([c])))))}finally{$CLJS.UX=e,$CLJS.VX=d}},replace_clause:function(a,b,c,d){return $CLJS.Vxa.l($CLJS.H([a,b,$CLJS.z9.l($CLJS.H([$CLJS.Ly(c,$CLJS.H([$CLJS.ji,!0]))])),$CLJS.z9.l($CLJS.H([$CLJS.Ly(d,$CLJS.H([$CLJS.ji,!0]))]))]))},drop_stage:function(a){return $CLJS.Yxa.l($CLJS.H([a]))},
join_lhs_display_name:function(a,b,c,d){return $CLJS.nxa.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Owa.l($CLJS.H([a,b,c]))},segment_metadata:function(a,b){return $CLJS.g9(a,b)},pivot_types:function(a){return $CLJS.re($CLJS.cf.g($CLJS.Xg,$CLJS.Dwa.l($CLJS.H([a]))))},native_extras:function(a){return $CLJS.Fk($CLJS.Fxa.l($CLJS.H([a])))},available_temporal_buckets:i$,with_binning:function(a,b){return $CLJS.rwa.l($CLJS.H([a,b]))},append_stage:function(a){return $CLJS.Xxa.l($CLJS.H([a]))},
join:function(a,b,c){return $CLJS.fxa.l($CLJS.H([a,b,c]))},aggregations:function(a,b){return $CLJS.re($CLJS.x9.l($CLJS.H([a,b])))},drill_thru:n$,raw_native_query:function(a){return $CLJS.zxa.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.re($CLJS.Xwa.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.eya.l($CLJS.H([a,b]))},valid_filter_for_QMARK_:function(a,b){return $CLJS.FZ(a)&&$CLJS.FZ(b)||$CLJS.OZ(a)&&$CLJS.OZ(b)||$CLJS.DZ(a)&&$CLJS.DZ(b)||$CLJS.Dz($CLJS.tA.h(a),
$CLJS.tA.h(b))},with_join_conditions:function(a,b){return $CLJS.uxa.l($CLJS.H([a,$CLJS.Ly(b,$CLJS.H([$CLJS.ji,!0]))]))},update_lat_lon_filter:function(a,b,c,d,e){e=$CLJS.Ly(e,$CLJS.H([$CLJS.ji,!0]));c=c$(a,b,c);d=c$(a,b,d);return $CLJS.bxa.l($CLJS.H([a,b,c,d,e]))},available_metrics:function(a,b){return $CLJS.re($CLJS.xxa.l($CLJS.H([a,b])))},with_different_database:m$,with_fields:function(a,b,c){return $CLJS.Twa.l($CLJS.H([a,b,c]))},is_column_metadata:function(a){return $CLJS.sd(a)&&$CLJS.E.g($CLJS.IJ,
$CLJS.Vs.h(a))},filter_args_display_name:function(a,b,c){return $CLJS.Nwa.l($CLJS.H([a,b,c]))},join_clause:function(a,b){return $CLJS.gxa.l($CLJS.H([a,b]))},aggregation_clause:function(a,b){return void 0===b?$CLJS.w9.l($CLJS.H([a])):$CLJS.w9.l($CLJS.H([a,b]))},external_op:function(a){a=$CLJS.ywa.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.qD);var c=$CLJS.J.g(b,$CLJS.Pi);b=$CLJS.J.g(b,$CLJS.Kr);return{operator:a,options:$CLJS.Fk(c),args:$CLJS.re(b)}},with_join_fields:function(a,b){return $CLJS.sxa.l($CLJS.H([a,
"string"===typeof b?$CLJS.mh.h(b):b]))},visible_columns:function(a,b){return $CLJS.i_($CLJS.mh.g("visible-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){var c=$CLJS.EV(a,b),d=$CLJS.Y_.j(a,b,c);c=$CLJS.W_.j(a,b,c);return $CLJS.re($CLJS.i3(a,b,d,c))})},update_numeric_filter:function(a,b,c,d,e){c=c$(a,b,c);return $CLJS.cxa.l($CLJS.H([a,b,c,d,e]))},available_binning_strategies:h$,update_temporal_filter:function(a,b,c,d,e){c=c$(a,b,c);return $CLJS.dxa.l($CLJS.H([a,b,c,d,e]))},find_column_indexes_from_legacy_refs:function(a,
b,c,d){var e=$CLJS.WE.h($CLJS.EV(a,b)),f=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null)}),e);e=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null)}),e);var k=$CLJS.VX,l=$CLJS.UX;$CLJS.VX=f;$CLJS.UX=e;try{var m=$CLJS.mk.g(mza,c),t=$CLJS.cf.g(a$,d);return $CLJS.re(aza(a,b,
t,m))}finally{$CLJS.UX=l,$CLJS.VX=k}},expression:function(a,b,c,d){return $CLJS.Gwa.l($CLJS.H([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.mh.h(a):a;b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.cya.l($CLJS.H([a,b]))},order_bys:function(a,b){return $CLJS.re($CLJS.Nxa.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.i_($CLJS.mh.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),c,function(d){return $9($CLJS.vxa.l($CLJS.H([$CLJS.B3(a,b),b,
d])))})},can_run:function(a){return $CLJS.i_(pza,a,function(){return $CLJS.Oxa.l($CLJS.H([a]))})},fieldable_columns:function(a,b){return $CLJS.i_($CLJS.mh.g("fieldable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.Pwa.l($CLJS.H([a,b])))})},template_tags:function(a){return $CLJS.Fk($CLJS.Bxa.l($CLJS.H([a])))},selected_aggregation_operators:function(a,b){return $CLJS.re($CLJS.pwa.l($CLJS.H([$CLJS.y(a),b])))},order_by_clause:g$,filterable_columns:function(a,b){return $CLJS.i_($CLJS.mh.g("filterable-columns",
["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.Wwa.l($CLJS.H([a,b])))})},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.re($CLJS.jxa.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.kxa.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.re($CLJS.Vwa.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.re($CLJS.hxa.l($CLJS.H([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.i1(a,
b)},with_expression_name:function(a,b){return $CLJS.Jwa.l($CLJS.H([a,b]))},orderable_columns:function(a,b){return $CLJS.i_($CLJS.mh.g("orderable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.Z7.g(a,b))})},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.$wa.l($CLJS.H([a,b,$CLJS.Ly(c,$CLJS.H([$CLJS.ji,!0]))]))},suggestedName:function(a){return $CLJS.wxa.l($CLJS.H([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.Ewa.l($CLJS.H([a,b,c,d]))},has_write_permission:function(a){return $CLJS.Ixa.l($CLJS.H([a]))},
metadataProvider:X9,filter_clause:l$,fields:function(a,b){return $CLJS.re($CLJS.Qwa.l($CLJS.H([a,b])))},dependent_metadata:function(a){return $CLJS.re($CLJS.cf.g($CLJS.Fk,$CLJS.Kwa.l($CLJS.H([a]))))},available_aggregation_operators:function(a,b){return $CLJS.re($CLJS.owa.l($CLJS.H([a,b])))},breakouts:function(a,b){return $CLJS.re($CLJS.vwa.l($CLJS.H([a,b])))},joinable_columns:function(a,b,c){return $CLJS.re($CLJS.pxa.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.re($CLJS.xwa.l($CLJS.H([a])))},
describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.aya.l($CLJS.H([a,b]))},breakout_column:function(a,b,c){return $CLJS.twa.l($CLJS.H([a,b,c]))},joins:function(a,b){return $CLJS.re($CLJS.qxa.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.yxa.l($CLJS.H([X9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.re($CLJS.exa.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.Fk.l(f$($CLJS.LX.h(a)),$CLJS.H([$CLJS.Pj,$CLJS.$z]))},stage_count:function(a){return $CLJS.Qxa.l($CLJS.H([a]))},
expression_clause:function(a,b,c){return $CLJS.z9.l($CLJS.H([$CLJS.Lwa.l($CLJS.H([$CLJS.mh.h(a),b,$CLJS.Ly(c,$CLJS.H([$CLJS.ji,!0]))]))]))},find_matching_column:function(a,b,c,d){return $CLJS.Fwa.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.Gxa.l($CLJS.H([a,$CLJS.Ly(b,$CLJS.H([$CLJS.ji,!0]))]))},join_strategy:function(a){return $CLJS.oxa.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.Vs.h(b);switch(c instanceof $CLJS.M?c.S:null){case "metadata/table":return{databaseId:$CLJS.UO.h(a),
tableId:$CLJS.Ji.h(b)};case "metadata/card":return{databaseId:$CLJS.UO.h(a),tableId:["card__",$CLJS.p.h($CLJS.Ji.h(b))].join(""),cardId:$CLJS.Ji.h(b),isModel:$CLJS.CY.h(b)};default:return a=$CLJS.Mz($CLJS.Ex),$CLJS.n($CLJS.Lz("metabase.lib.js",a))&&$CLJS.Kz("metabase.lib.js",a,$CLJS.xv.l($CLJS.H(["Cannot provide picker-info for",$CLJS.Vs.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.Mwa.l($CLJS.H([a,b,c]));return $CLJS.tU(function(d){if($CLJS.sd(d)&&$CLJS.E.g($CLJS.J8,$CLJS.Vs.h(d))){var e=
$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.qD);var f=$CLJS.J.g(e,$CLJS.Pi);e=$CLJS.J.g(e,$CLJS.Kr);return{operator:$CLJS.Xg(d),options:$CLJS.Fk($CLJS.Bl(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oF,$CLJS.Kk],null))),args:$CLJS.re($CLJS.cf.g(function(k){return k instanceof $CLJS.M?$CLJS.$z(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,b,c){b=$CLJS.x9.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.uD);
return new $CLJS.P(null,2,5,$CLJS.Q,[l,m],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.uD);return new $CLJS.P(null,2,5,$CLJS.Q,[m,l],null)}),b);var d=$CLJS.VX,e=$CLJS.UX;$CLJS.VX=a;$CLJS.UX=b;try{var f=$CLJS.Ly(c,$CLJS.H([$CLJS.ji,!0])),k=$CLJS.z($CLJS.CX(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,$CLJS.WE],null),new $CLJS.P(null,1,5,$CLJS.Q,[f],null)));return $CLJS.WX.h(k)}finally{$CLJS.UX=e,$CLJS.VX=d}},format_relative_date_range:function(a,
b,c,d,e){b=$CLJS.mh.h(b);d=null==d?null:$CLJS.mh.h(d);e=$CLJS.Ly(e,$CLJS.H([$CLJS.ji,!0]));return $CLJS.k2((0,$CLJS.LH)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.re($CLJS.nwa.l($CLJS.H([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.axa.l($CLJS.H([a,b,c]))},can_save:function(a){return $CLJS.i_(oza,a,function(){return $CLJS.Pxa.l($CLJS.H([a]))})},available_segments:function(a,b){return $CLJS.re($CLJS.Wxa.l($CLJS.H([a,b])))},join_condition_operators:function(a,
b,c,d){return $CLJS.re($CLJS.ixa.l($CLJS.H([a,b,c,d])))},aggregation_column:function(a,b,c){return $CLJS.mwa.l($CLJS.H([a,b,c]))},expressionable_columns:function(a,b,c){return $CLJS.i_($CLJS.mh.g("expressionable-columns",["stage-",$CLJS.p.h(b),"-",$CLJS.p.h(c)].join("")),a,function(){return $CLJS.re($CLJS.Iwa.l($CLJS.H([a,b,c])))})},filter:function(a,b,c){return $CLJS.Uwa.l($CLJS.H([a,b,$CLJS.Ly(c,$CLJS.H([$CLJS.ji,!0]))]))},metric_metadata:function(a,b){return $CLJS.R3(a,b)},change_direction:function(a,
b){return $CLJS.Kxa.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.re($CLJS.Cwa.l($CLJS.H([a,$CLJS.mh.h(b)])))},filter_drill_details:function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.CE);var c=$CLJS.J.g(b,$CLJS.Ci),d=$CLJS.J.g(b,$CLJS.EK);b=$CLJS.J.g(b,$CLJS.hj);return{column:c,query:a,stageIndex:d,value:$CLJS.E.g(b,$CLJS.MK)?null:b}},remove_field:function(a,b,c){return $CLJS.Swa.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.Dxa.l($CLJS.H([a,gza(b)]))},expressions:function(a,
b){return $CLJS.re($CLJS.Hwa.l($CLJS.H([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.re($CLJS.rxa.l($CLJS.H([a,b,c])))},with_join_strategy:function(a,b){return $CLJS.txa.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.Lxa.l($CLJS.H([a,b,c,$CLJS.mh.h(d)]))},field_values_search_info:function(a,b){var c=$CLJS.nk.j;if($CLJS.n(b)){var d=$CLJS.Ji.h(b),e=$CLJS.Ji.h;var f=$CLJS.LZ(b)?b:null;$CLJS.n(f)?a=f:(f=$CLJS.Bz(b,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,$CLJS.SJ],null)),
a=$CLJS.n(f)?$CLJS.$0(a,f):null,a=$CLJS.n(a)?a:b);a=$ya(a)?a:null;e=e.call($CLJS.Ji,a);d=$CLJS.kl(d)?d:null;e=$CLJS.kl(e)?e:null;$CLJS.n(b)?(b=$CLJS.O(b),a=$CLJS.J.g(b,$CLJS.UY),b=$CLJS.E.g(a,$CLJS.WY)?$CLJS.fj:$CLJS.n(a)?a:$ya(b)?$CLJS.yY:$CLJS.cz):b=$CLJS.cz;b=new $CLJS.h(null,3,[$CLJS.SJ,d,$CLJS.v7,e,$CLJS.UY,b],null)}else b=null;return $CLJS.Fk($CLJS.Ez(c.call($CLJS.nk,b,$CLJS.UY,$CLJS.Xg),hza))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.mh.h(a):a;b="string"===typeof b?
$CLJS.mh.h(b):b;return $CLJS.bya.l($CLJS.H([a,b]))},breakout:function(a,b,c){return $CLJS.swa.l($CLJS.H([a,b,$CLJS.B9.l($CLJS.H([c]))]))},database_id:function(a){return $CLJS.zwa.l($CLJS.H([a]))},required_native_extras:function(a,b){return $CLJS.re($CLJS.cf.g($CLJS.$z,$CLJS.Exa.l($CLJS.H([X9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.Txa.l($CLJS.H([a,b,c]))},joined_thing:function(a,b){return $CLJS.v3(a,b)},legacy_column__GT_metadata:c$,with_native_query:function(a,b){return $CLJS.Axa.l($CLJS.H([a,
b]))},breakoutable_columns:function(a,b){return $CLJS.i_($CLJS.mh.g("breakoutable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.uwa.l($CLJS.H([a,b])))})},rename_join:function(a,b,c,d){return $CLJS.Uxa.l($CLJS.H([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.Rxa.l($CLJS.H([a,b]))}};